<template>
    <div class="container-fluid">
        <div class="row mt-3">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center mb-3">
                            <h4 class=" text-primary">Welcome! Rita Nyein Aung</h4>
                        </div>
                        <div id="chart">
                            <apexchart type="donut" width="380" :options="chartOptions" :series="series">
                            </apexchart>
                        </div>
                        <div class="mt-4 mb-3">
                            <h4>Middle Management Level</h4>
                        </div>
                        <place-holder v-if="loading"></place-holder>
                        <div class="table-responsive">
                            <table class="table table-striped dt-responsive w-100 mb-3" id="module-list-datatable"
                                v-show="!loading">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Module Name</th>
                                        <th>Expired Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in moduleLists" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td>{{ item.module_name }}</td>
                                        <td><span class="text-info">{{ item.expired_date }}</span></td>
                                        <td>

                                            <span v-if="item.status == 1" class="text-primary">Complete</span>
                                            <span v-else class="text-warning">Pending</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
// import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";



export default {
    components: {
    },
    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            options: {},
            series: [70, 30],
            chartOptions: {
                labels: ["Complete", "Left"],
                chart: {
                    width: 380,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                dataLabels: {
                    enabled: false,
                    style: {
                        fontSize: '20px',
                    },
                },
                fill: {
                    type: 'gradient',
                    // colors: ['#00acc1', '#e0e0e0']
                },
                legend: {
                    formatter: function (val, opts) {
                        console.log(val, opts, "data");
                        return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%"
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            moduleLists: [
                {
                    id: 0,
                    module_name: "Hello",
                    expired_date: "4.4.2023",
                    status: 1,
                },
                {
                    id: 0,
                    module_name: "Hi",
                    expired_date: "4.4.2023",
                    status: 1,
                },
                {
                    id: 0,
                    module_name: "Hey",
                    expired_date: "4.4.2023",
                    status: 0,
                }
            ],
            baseUrl: process.env.VUE_APP_BASE_URL,
        };
    },
    methods: {
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#module-list-datatable").DataTable().draw();
        },
    },
    created() {
        this.clearFilter();
    },
};
</script>
  
<style>
.apexcharts-canvas {
    position: relative;
    user-select: none;
    margin: auto;
    border-radius: 15px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
</style>